import displ from "../main/resourses/display.png"
import "../main/css/main-css.css"
import { Button, LoadPanel } from "devextreme-react";
import { Link } from "react-router-dom";
import { YMaps, Map, Placemark, ZoomControl } from "@pbe/react-yandex-maps";
import MainHeader from "../../base-components/MainHeader";
import { useState, useEffect } from "react";
import MailMessage from "./components/MailMessage";
import AuthPopap from "../../base-components/AuthPopap";
import getBaseInfo from "../../base-services/getBaseInfo";

const MainPage = () => {

    const baseInfoInit = {
        id: null,
        baseInformationId: null,
        logoText: "ООО \"РЭМ-сервис\"",
        bigText: "ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ \"РЭМ-СЕРВИС\"",
        smallText: "Производство пара и горячей воды (тепловой энергии) котельными",
        adress: "614065, Пермский край, г. Пермь, ул. Верхне-Муллинская, д. 80а",
        phone: "+7 (342) 294-69-22",
        email: "rem-servise@bk.ru",
        dolgota: 57.955610,
        shirota: 56.131572
    }

    const [baseInfo, setBaseInfo] = useState(baseInfoInit)
    const [popupNotification, setPopupNotification] = useState(false)
    const [popupNotificationMessage, setPopupNotificationMessage] = useState(null)
    const [visibleMessage, setVisibleMessage] = useState(false)
    const [visibleLoading, setVisibleLoading] = useState(false)

    const handlePopapNotification = (message) => {
        if (message) {
            setPopupNotificationMessage(message)
            setPopupNotification(true)
        }
    }

    const getBaseInfoHandler = async () => {
        setVisibleLoading(true)
        var response = await getBaseInfo()
        if (response.success) {
            if (response.content !== null) {
                setBaseInfo(response.content)
            }
        }
        else {
            handlePopapNotification(response.message)
        }
        setVisibleLoading(false)
    }

    useEffect(() => {
        document.title = "ООО \"РЭМ-сервис\" | Производство тепловой энергии в Пермском крае"
        getBaseInfoHandler()
    }, [])

    return (
        <div>
            <LoadPanel
                visible={visibleLoading}
                message='Загрузка...' />
            <MailMessage visible={visibleMessage} onHide={() => setVisibleMessage(false)} />
            <MainHeader />
            <div className="body-container">
                <div className="left-body">
                    <div className="large-text">
                        {baseInfo?.bigText}
                    </div>
                    <div className="small-text">
                        {baseInfo?.smallText}
                    </div>
                    <div className="buttons">
                        <Button onClick={() => setVisibleMessage(true)} className="question-button" text="Задать вопрос" />
                        <Link className="link" to={"/news"}>Новости &#8594;</Link>
                    </div>
                </div>

                <div className="right-body">
                    <img className="display-logo" src={displ} alt="SVG Image" />
                </div>
            </div>

            <div className="contacts-container">
                <div className="contact-header">Контактные данные</div>

                <div className="contacts-content">
                    <div className="contact-side">
                        <div className="contact-unit">
                            <p style={{ fontWeight: 'bold' }}>Адрес:</p>
                            {baseInfo?.adress}
                        </div>

                        <div className="contact-unit">
                            <p style={{ fontWeight: 'bold' }}>Телефон:</p>
                            {baseInfo?.phone}
                        </div>
                        <div className="contact-unit">
                            <p style={{ fontWeight: 'bold' }}> Элекронная почта:</p>
                            {baseInfo?.email}
                        </div>
                    </div>

                    <div className="contact-side">
                        <YMaps>
                            <Map style={{ width: '100%', height: '100%' }} defaultState={{ center: [baseInfo?.dolgota ?? 0, baseInfo?.shirota ?? 0], zoom: 17 }}>
                                <Placemark geometry={[baseInfo?.dolgota ?? 0, baseInfo?.shirota ?? 0]} />
                                <ZoomControl options={{ float: "right" }} />
                            </Map>
                        </YMaps>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default MainPage